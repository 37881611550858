//
// index.tsx - Plan Info related functionality
//

import { FetchHookResult } from "@data-types/generic-hook-type";
import { ProjectPlan } from "@data-types/plan-info-types";
import { renderAnalyzer, swrFetcher } from "@lib/client-side";
import useSWR from "swr";

// Utils

/**
 * Helper function to parse size description into individual CPU, RAM, and storage fields.
 *
 * @param {string} sizeDescription - The size description string, e.g., "0.5vCPU / 512MB / 5GB".
 * @returns {{ cpu: string; ram: string; storage: string }} Parsed fields for CPU, RAM, and storage.
 */
function parseSizeDescription(sizeDescription: string): {
  cpu: string;
  ram: string;
  storage: string;
} {
  const [cpu, ram, storage] = sizeDescription.split(" / ");
  return { cpu, ram, storage };
}

// Hooks

/**
 * Custom hook to fetch and process user plan information.
 *
 * This hook retrieves user plan data from the API, processes the plan description into separate
 * fields (CPU, RAM, and storage), and provides various states for managing display in the UI.
 *
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing project storage info data.
 *
 * @returns {FetchHookResult<ProjectPlan>} An object containing:
 *   - `data`: The processed plan information or `undefined` if no data is available.
 *   - `isLoading`: `true` if data is currently loading.
 *   - `isError`: Any error encountered during data fetching.
 *   - `isValidating`: `true` if the data is in the process of revalidating.
 *   - `showLoader`: `true` if a loading indicator should be shown.
 *   - `hasData`: `true` if there is plan data available.
 *   - `emptyData`: `true` if plan data is available but empty.
 *   - `mutate`: A function to manually revalidate and refresh the fetched data.
 */
export function useGetUserPlanInfo(
  refreshInterval?: number
): FetchHookResult<ProjectPlan> {
  const { data, error, isValidating, mutate } = useSWR(
    ["/api/plan", "useGetPlanInfo"],
    swrFetcher,
    {
      refreshInterval: refreshInterval ?? 0,
      revalidateOnFocus: false,
    }
  );

  // Analyze data to determine if it has content or is empty
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    true
  );

  // Parse the plan information if data is available
  const planInfo: ProjectPlan | undefined = hasData
    ? {
        ...data.value[0],
        ...parseSizeDescription(data.value[0].size_description),
      }
    : undefined;

  return {
    data: planInfo,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
    mutate,
  };
}
