//
// index.tsx - Projects related functionality
//

import {
  getDatabasesTotalSize,
  useGetProjectDatabases,
} from "@custom-hooks/Databases";
import { useGetUserPlanInfo } from "@custom-hooks/PlanInfo";
import { useCustomRouter } from "@custom-hooks/useCustomRouter";
import { FetchHookResult } from "@data-types/generic-hook-type";
import { EnvironmentType, Project, Projects } from "@data-types/projects-types";
import { renderAnalyzer, swrFetcher } from "@lib/client-side";
import { formatBytes, gigabytesToBytes } from "@lib/iso-utils";
import { useEffect, useState } from "react";
import useSWR from "swr";

// Types

// Utils

export const environmentType: EnvironmentType = [
  { value: 0, label: "Development" },
  { value: 1, label: "Staging" },
  { value: 2, label: "Production" },
];

/**
 * Converts an environment code to a string with corresponding label and color.
 * @param envCode - The environment code (0 = Development, 1 = Staging, 2 = Production).
 * @param theme - The theme object for accessing color codes.
 * @returns {EnvironmentInfo} An object containing color and label based on the environment code.
 */

type EnvironmentInfo = {
  color: string;
  label: "DEVELOPMENT" | "STAGING" | "PRODUCTION";
};

export function convertEnvCodeToEnvString(
  envCode: number,
  theme?: any
): EnvironmentInfo {
  let env: EnvironmentInfo;

  switch (envCode) {
    case 0:
      env = {
        color: theme ? theme.palette.secondary.accent13 : "",
        label: "DEVELOPMENT",
      };
      break;
    case 1:
      env = {
        color: theme ? theme.palette.secondary.accent14 : "",
        label: "STAGING",
      };
      break;
    case 2:
      env = {
        color: theme ? theme.palette.secondary.accent1 : "",
        label: "PRODUCTION",
      };
      break;
    default:
      env = {
        color: theme ? theme.palette.secondary.accent13 : "",
        label: "DEVELOPMENT",
      };
      break;
  }

  return env;
}

// Hooks

/**
 * Custom hook to fetch project data and provide various states for managing the display.
 *
 * This hook retrieves project data from the API
 * offers several states, such as loading, error, and validation, to help manage data display in the UI.
 *
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing projects data.
 *
 * @returns {FetchHookResult<Projects>} An object containing:
 *   - `data`: The list of projects or `undefined` if no data is available.
 *   - `isLoading`: `true` if the data is currently loading; otherwise, `false`.
 *   - `isError`: Any error encountered during data fetching.
 *   - `isValidating`: `true` if the data is in the process of revalidating; otherwise, `false`.
 *   - `showLoader`: `true` if a loading indicator should be shown.
 *   - `hasData`: `true` if there is project data available.
 *   - `emptyData`: `true` if project data is available but empty.
 *   - `mutate`: A function to manually revalidate and refresh the fetched data.
 */
export function useGetUserProjects(
  refreshInterval?: number
): FetchHookResult<Projects> {
  // Fetch user's projects data with SWR
  const { data, error, isValidating, mutate } = useSWR(
    () => [`/api/projects`, "useGetUserProjects"],
    swrFetcher,
    {
      refreshInterval: refreshInterval ?? 0,
      revalidateOnFocus: true, // Revalidate data when window regains focus
    }
  );

  // Get data status indicators for display
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    true
  );

  // If data exists and has values to process
  if (hasData) {
    data.value.forEach((project: Project) => {
      try {
        // Parse the 'regions' JSON string from the first item in 'data.value' into an array
        const regionsList = JSON.parse(project.regions);
        // Check if 'regionsList' has at least one region object
        if (regionsList[0]) {
          // Add 'regionsList' as a new property in 'data.value[0]'
          project.regionsList = regionsList;
        }
      } catch (error) {}
    });
  }

  return {
    data: data?.value,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
    mutate,
  };
}

/**
 * Custom hook to fetch and analyze information for a specific project by its ID.
 *
 * This hook retrieves project data from the API based on the provided `projectId`.
 * It includes loading, error, and validation states for easier UI management. Additionally,
 * it retrieves `nodes_count` from the list of user projects if available.
 *
 * @param {string} projectId - The ID of the project to fetch data for.
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing project info data.
 *
 * @returns {FetchHookResult<Project & { nodes_count?: number }>} An object containing:
 *   - `data`: The project data, including `nodes_count` if available, or `undefined` if no data is available.
 *   - `isLoading`: `true` if the data is currently loading; otherwise, `false`.
 *   - `isError`: Any error encountered during data fetching.
 *   - `isValidating`: `true` if the data is in the process of revalidating; otherwise, `false`.
 *   - `showLoader`: `true` if a loading indicator should be shown.
 *   - `hasData`: `true` if there is project data available.
 *   - `emptyData`: `true` if project data is available but empty.
 *   - `mutate`: A function to manually revalidate and refresh the fetched data.
 */
export function useGetProjectInfo(
  projectId?: string,
  refreshInterval?: number
): FetchHookResult<Project & { nodes_count?: number }> {
  // Fetch project data with SWR if projectId is valid
  const { data, error, isValidating, mutate } = useSWR(
    () => projectId && [`/api/projects/${projectId}`, "useGetProjectInfo"],
    swrFetcher,
    {
      refreshInterval: refreshInterval ?? 0,
      revalidateOnFocus: false, // Prevent revalidation on window focus
    }
  );

  // Determine the status of data for UI display purposes
  const { hasData, emptyData, showLoader } = renderAnalyzer(
    data,
    error,
    isValidating,
    true
  );

  // If data exists and has values to process
  if (hasData) {
    try {
      // Parse the 'regions' JSON string from the first item in 'data.value' into an array
      const regionsList = JSON.parse(data.value.regions);
      // Check if 'regionsList' has at least one region object
      if (regionsList[0]) {
        // Add 'regionsList' as a new property in 'data.value[0]'
        data.value.regionsList = regionsList;
      }
    } catch (error) {}
  }
  // Return hook's state and data indicators, including nodes_count if available
  return {
    data: data ? { ...data.value } : undefined,
    isLoading: !error && !data,
    isError: error,
    isValidating,
    showLoader,
    hasData,
    emptyData,
    mutate,
  };
}

/**
 * Custom hook to fetch and calculate storage usage for a specific project, with a warning threshold.
 *
 * This hook retrieves storage information from the user's plan and databases associated with the provided `projectId`.
 * It calculates the used storage and determines if it exceeds a specified warning threshold.
 *
 * @param {string} projectId - The ID of the project to fetch storage information for.
 * @param {number} [warningThreshold=90] - The percentage threshold at which to trigger a warning (default is 90%).
 * @param {number} [refreshInterval] - Optional interval (in milliseconds) for refreshing project storage info data.
 *
 * @returns {Object} - An object containing:
 *   - `data`: Storage information (available, used, usage percentage) or `undefined` if data is unavailable.
 *   - `isLoading`: `true` if either plan or database data is loading; otherwise, `false`.
 *   - `isError`: Any error encountered during data fetching.
 *   - `isValidating`: `true` if data is being revalidated.
 *   - `showLoader`: `true` if a loading indicator should be shown.
 *   - `hasData`: `true` if both plan and database data are available.
 *   - `emptyData`: `true` if either plan or database data is available but empty.
 *   - `mutate`: A function to manually revalidate and refresh the fetched data.
 */
export function useGetProjectStorageInfo(
  projectId: string,
  warningThreshold: number = 90,
  refreshInterval?: number
) {
  // Fetch user plan data
  const {
    data: planInfo,
    hasData: availablePlanInfo,
    isLoading: loadingPlanInfo,
    isValidating: validatingPlanInfo,
    isError: errorPlanInfo,
    showLoader: showLoaderPlanInfo,
    emptyData: emptyDataPlanInfo,
    mutate: updatedUserPlanInfo,
  } = useGetUserPlanInfo(refreshInterval);

  // Fetch project databases data
  const {
    data: databases,
    hasData: availableDatabases,
    isLoading: loadingDatabases,
    isValidating: validatingDatabases,
    isError: errorDatabases,
    showLoader: showLoaderDatabases,
    emptyData: emptyDataDatabases,
    mutate: updatedProjectDatabases,
  } = useGetProjectDatabases(projectId, refreshInterval);

  // Initialize variables for storage info
  let availableStorage,
    usedStorage,
    avaibleStorageInBytes,
    usedStorageInBytes,
    usedStoragePercentage,
    warning;

  // Calculate storage usage if both plan and database data are available
  if (availablePlanInfo && availableDatabases) {
    availableStorage = planInfo!.storage;

    // Convert available storage from GB to bytes
    avaibleStorageInBytes = gigabytesToBytes(
      parseFloat(availableStorage.replace("GB", ""))
    );

    // Calculate used storage in bytes from database sizes
    usedStorageInBytes = getDatabasesTotalSize(databases!);
    usedStorage = formatBytes(usedStorageInBytes!);

    // Calculate used storage as a percentage
    usedStoragePercentage = (usedStorageInBytes! / avaibleStorageInBytes) * 100;

    // Determine if usage exceeds the warning threshold
    warning = usedStoragePercentage > warningThreshold;

    return {
      data: {
        availableStorage,
        usedStorage,
        avaibleStorageInBytes,
        usedStorageInBytes,
        usedStoragePercentage,
        warning,
      },
      isLoading: false,
      isError: false,
      isValidating: false,
      showLoader: false,
      hasData: true,
      emptyData: false,
    };
  }

  // Refresh data that determinate the storage info
  const mutate = () => {
    updatedUserPlanInfo();
    updatedProjectDatabases();
  };

  // Return loading and error states, or undefined data if not available
  return {
    data: undefined,
    isLoading: loadingPlanInfo || loadingDatabases,
    isError: errorPlanInfo || errorDatabases,
    isValidating: validatingPlanInfo || validatingDatabases,
    showLoader: showLoaderPlanInfo || showLoaderDatabases,
    hasData: availablePlanInfo && availableDatabases,
    emptyData: emptyDataPlanInfo || emptyDataDatabases,
    noDatabases: emptyDataDatabases,
    mutate,
  };
}

/**
 * Custom hook to retrieve the actual project ID based on query parameters and the user's project list.
 *
 * This hook checks if the `projectId` from the URL query exists within the user's projects.
 * If it does, the hook returns that `projectId`; otherwise, it returns `false` if the ID is not found,
 * or `undefined` if there are no projects or the `projectId` query parameter is missing.
 *
 * @returns {string | false | undefined} The actual project ID if it exists, `false` if the ID does not match
 * any project, or `undefined` if the project data is unavailable.
 */
export function useGetCurrentProjectId(): string | false | undefined {
  // Retrieve all user projects
  const { data: projects } = useGetUserProjects();

  // Get query parameters from router
  const { query } = useCustomRouter();

  // Ensure projectId is a string
  const projectId =
    typeof query?.projectId === "string" ? query.projectId : undefined;

  // Determine actual project ID based on the projects list and query parameter
  const actualProjectId =
    projects && Array.isArray(projects) && projectId
      ? projects.some((obj) => obj.id === projectId)
        ? projectId
        : false
      : undefined;

  return actualProjectId;
}

/**
 * Custom hook to manage polling based on a project's `creating_count`.
 *
 * This hook handles polling based on the `creating_count` value for a project. It starts polling
 * when `creating_count > 0` and stops when it reaches `0`. Additionally, it triggers one or more
 * callback functions when polling stops.
 *
 * @param {string} projectId - The unique identifier of the project to monitor.
 * @param {(() => void) | (() => void)[]} callbacks - A function or an array of functions to invoke
 * when polling stops and `creating_count` reaches `0`.
 * @returns {{ isPolling: boolean }} An object containing:
 *   - `isPolling`: A boolean indicating whether polling is currently active.
 */
export function useProjectPollingManager(
  projectId: string,
  callbacks: (() => void) | (() => void)[]
): { isPolling: boolean } {
  // State to track whether polling is active
  const [isPolling, setIsPolling] = useState(false);

  // Fetch project info to monitor `creating_count`
  const { data: projectInfo, hasData: availableProjectInfo } =
    useGetProjectInfo(
      projectId,
      isPolling ? 10000 : 0 // Poll every 10 seconds if active
    );

  // Extract `creating_count` from the project data
  const creatingCount = availableProjectInfo ? projectInfo?.creating_count : 0;

  useEffect(() => {
    // Start polling if `creating_count > 0` and polling is not already active
    if (creatingCount && creatingCount > 0 && !isPolling) {
      setIsPolling(true);
    }

    // Stop polling if `creating_count` becomes `0`
    if (creatingCount === 0 && isPolling) {
      setIsPolling(false);

      // Trigger callbacks when polling stops
      if (callbacks) {
        if (Array.isArray(callbacks)) {
          // Invoke each callback in the array
          callbacks.forEach((callback) => {
            callback();
          });
        } else {
          // Invoke the single callback
          callbacks();
        }
      }
    }
  }, [creatingCount, isPolling, callbacks]);

  return {
    isPolling, // Indicates whether polling is active
  };
}
